<template>
  <section>
    <div class="my-4 bg-white rounded-md p-4 w-full">
      <div class="flex justify-between">
        <div class="flex gap-2 items-center font-bold mb-2">
          <i class="pi pi-dollar font-bold"></i>
          <p class="text-lg">Pagos recibidos</p>
        </div>
        <Button @click="$router.push({ name: 'pharmasan.ventas.ov.pagos-recibidos.historial' })" icon="pi pi-replay" label="Historial" />
      </div>
      <div class="flex flex-wrap gap-2 my-2">
        <div class="gap-2" >
          <label for="fechaInicio" class="w-full text-xs text-gray-600">Fecha inicio</label>
          <input id="fechaInicio" class="border rounded-md p-2 w-full text-sm" v-model="filtros.fechaInicio" type="date">
        </div>
        <div class="gap-2" >
          <label for="fechaFin" class="w-full text-xs text-gray-600">Fecha fin</label>
          <input id="fechaFin" class="border rounded-md p-2 w-full text-sm" v-model="filtros.fechaFin" type="date">
        </div>
        <div class="gap-2" style="display: none">
          <label for="fechaFin" class="w-full text-xs text-gray-600">Valor máximo</label>
          <InputNumber class="w-full" inputClass="text-sm w-full" :inputStyle="{padding: '0.55rem', border: '1px solid rgba(226, 232, 240, var(--tw-border-opacity))'}" :max="999999" v-model="filtros.valor" />
        </div>
        <div class="gap-2">
          <label for="fechaFin" class="w-full text-xs text-gray-600">Ordenes</label>
          <InputText @keydown="validacionOrdenes($event)" @keyup.enter="guardarOrdenes" :onfocus="guardarOrdenes" v-model="ordenFiltrada" class="w-full text-sm border-gray-300" style="border: 1px solid rgba(226, 232, 240, var(--tw-border-opacity)); padding: 0.55rem"/>
          <div style="background-color: #f8f9fa;" class="flex flex-wrap overflow-auto max-h-20 gap-1 p-2" v-if="filtros.ordenes.length">
            <div v-for="(orden, i) in filtros.ordenes" :key="i">
              <div style="background-color: #EFF6FF;" class="p-1 gap-1 rounded-md flex text-blue-600">
                <button @click="filtros.ordenes.splice(filtros.ordenes.findIndex(a => a === orden), 1)" class="text-blue-600 rounded-full"><i class="pi pi-times text-xs"></i></button>
                <p>{{ orden }}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="gap-2">
          <label for="fechaFin" class="w-full text-xs text-gray-600">Num. Dcto.</label>
          <InputText @keyup.enter="obtenerPagos" v-model="filtros.numDcto" class="w-full text-sm border-gray-300" style="border: 1px solid rgba(226, 232, 240, var(--tw-border-opacity)); padding: 0.55rem"/>
        </div>
        <div class=" h-full flex gap-2 justify-end items-start" style="padding-top: 1.2rem;">
          <Button @click="obtenerPagos" label="Buscar" class="" style="height: fit-content; padding: 0.55rem;" icon="pi pi-search" />
        </div>
        <div class=" h-full flex gap-2 justify-end items-start" style="padding-top: 1.2rem;">
          <Button @click="limpiarFiltros" label="Limpiar" severity="secondary" class="" style="height: fit-content; padding: 0.55rem;" />
        </div>
        <div class=" h-full flex gap-2 justify-end items-start" style="padding-top: 1.2rem;">
          <Button @click="registarORCT" label="refrescar Pagos" severity="warning" style="height: fit-content; padding: 0.55rem;" />
        </div>
        <div class=" h-full flex gap-2 justify-end items-start" style="padding-top: 1.2rem;">
          <Button @click="displayModalSincronizarSap = true" severity="success" :disabled="!ordenesSeleccionadas.length" label="Sincronizar con SAP" class="w-full" style="height: fit-content; padding: 0.55rem;" icon="pi pi-undo" />
        </div>
      </div>
      <DataTable
        :value="listadoPagos.rows"
        tableStyle="min-width: 50rem"
        class="p-datatable-sm mt-4"
        v-model:selection="ordenesSeleccionadas"
        dataKey="Id"
        scrollHeight="600px"
        scrollable
      >
        <template #empty>No se encontraron registros.</template>
        <Column selectionMode="multiple" headerStyle="width: 3rem"></Column>
        <Column field="NumOv" class="text-sm" header="#OV" style="width: 10rem">
          <template #body="{data}">
            <div class="pt-3 idPadre">
              <p class="text-blue-600">{{ data.NumOv }}</p>
              <div class="idHijoDelete">
                <button  @click="eliminarPago(data.NumOv)" class="underline italic">Eliminar Pago</button>
              </div>
            </div>
          </template>
        </Column>
        <Column field="DocDate" class="text-center text-sm" header="Fecha de la orden"></Column>
        <!-- Juan me dijo que DocNum era numero de pago ATT: Maria P -->
        <!-- <Column field="DocNum" class="text-center text-sm" header="Numero de pago"></Column> -->
        <Column field="U_ACS_TpoIdentf" class="text-center text-sm" header="Identificación">
          <template #body="{data}">
            <div class=" pt-3">
              <p>{{ data.U_ACS_TpoIdentf }}</p>
              <p>{{ data.U_PHR_NumDcto }}</p>
            </div>
          </template>
        </Column>
        <Column field="Paciente" class="text-center text-sm" header="Nombre Paciente"></Column>
        <Column field="Valor" class="text-center text-sm" header="Valor"></Column>
        <Column field="estado" class="text-center text-sm" header="Estado">
          <template #body="{ data }">
            <div class="flex gap-2 items-center text-gray-600">
              <i class="pi pi-info-circle"></i>
              <p class="font-bold">
                {{ data.estado }}
              </p>
            </div>
          </template>
        </Column>
        <ColumnGroup type="footer" frozen>
          <Row>
              <Column footer="" :colspan="5" footerStyle="text-align:right" />
              <Column :footer="sumaSeleccion()" />
          </Row>
        </ColumnGroup>
      </DataTable>
      <Paginator v-model:first="offset"
        :rows="limit"
        :totalRecords="listadoPagos.count"
        :rowsPerPageOptions="pageOptions"
        @page="onPage($event)"
      />
    </div>
    <Dialog v-model:visible="displayModalSincronizarSap" modal header="Sincronizar con SAP" :style="{ width: '30vw' }">
      <div class="">
        <p class="w-full text-gray-600">Ingrese el numero de referencia de la consignación</p>
        <div class="w-full mt-2">
          <InputText class="w-full" type="text" v-model="referenciaBancaria" />
        </div>
        <div class="flex gap-2 justify-end">
          <Button class="mt-4" icon="pi pi-send" @click="sincronizarSap" :disabled="!referenciaBancaria" label="Enviar a SAP" />
          <Button class="mt-4" label="Cancelar" icon="pi pi-times" @click="cerrarModalSincronizarSap" severity="secondary" />
        </div>
      </div>
    </Dialog>
  </section>
</template>
<script>
import PagosRecibidosService from '../../../../services/pagos-recibidos.service'
import SyncPagosService from '../../../../services/sync-pagos'
import { ref, onMounted, computed } from 'vue'
import Swal from 'sweetalert2'
import dayjs from 'dayjs'
export default {
  name: 'pagosRecibidos',
  setup () {
    // Servicios
    const _PagosRecibidosService = ref(new PagosRecibidosService())
    const _SyncPagosService = ref(new SyncPagosService())
    // Referencias
    const pageOptions = ref([10, 20, 30, 50, 100, 200, 500, 1000])
    const listadoPagos = ref([])
    const offset = ref(0)
    const page = ref(0)
    const limit = ref(10)
    const ordenFiltrada = ref()
    const ordenesSeleccionadas = ref([])
    const displayModalSincronizarSap = ref(false)
    const referenciaBancaria = ref('')
    const filtros = ref({
      fechaInicio: dayjs().subtract(1, 'month').format('YYYY-MM-DD'),
      fechaFin: dayjs().format('YYYY-MM-DD'),
      numDcto: '',
      // estado: null,
      // valor: 0,
      ordenes: []
    })
    const estados = ref([
      {
        name: 'Pendiente',
        id: 0
      },
      {
        name: 'Procesando',
        id: 1
      },
      {
        name: 'Termino',
        id: 2
      },
      {
        name: 'Error',
        id: 3
      }
    ])
    // Computadas
    const params = computed(() => {
      return {
        offset: offset.value,
        page: page.value,
        limit: limit.value,
        filter: filtros.value
      }
    })
    // Metodos
    const obtenerPagos = () => {
      guardarOrdenes()
      _PagosRecibidosService.value.paginate(params.value).then(({ data }) => {
        listadoPagos.value = data
        listadoPagos.value.rows.map(a => {
          if (a.Status === 0) a.estado = 'Pendiente'
          if (a.Status === 1) a.estado = 'Procesando'
          if (a.Status === 2) a.estado = 'Termino'
          if (a.Status === 3) a.estado = 'Error'
          return a
        })
      })
    }
    const onPage = ({ first, page, pageCount, rows }) => {
      limit.value = rows
      obtenerPagos()
    }
    const validacionOrdenes = (e) => {
      if (/[^0-9\s]/.test(e.key)) {
        if (e.key === 'Backspace' || e.key === 'v' || e.key === 'Control') {
          if (e.key === 'v' && !e.ctrlKey) {
            e.preventDefault()
          }
        } else {
          e.preventDefault()
        }
      }
    }
    const guardarOrdenes = () => {
      if (!ordenFiltrada.value) return
      for (const orden of ordenFiltrada.value.split(' ')) {
        if (!filtros.value.ordenes.some(a => a === orden) && orden !== '') filtros.value.ordenes.push(orden)
      }
      ordenFiltrada.value = ''
      if (!pageOptions.value.includes(filtros.value.ordenes.length)) {
        pageOptions.value.push(filtros.value.ordenes.length)
        pageOptions.value.sort((a, b) => a - b)
        limit.value = filtros.value.ordenes.length
      } else {
        limit.value = filtros.value.ordenes.length
      }
    }
    const limpiarFiltros = () => {
      filtros.value = {
        // fechaInicio: dayjs().format('YYYY-MM-DD'),
        // fechaFin: dayjs().format('YYYY-MM-DD'),
        // estado: null,
        // valor: 0,
        ordenes: []
      }
      pageOptions.value = [10, 20, 30, 50, 100, 200, 500, 1000]
      ordenFiltrada.value = ''
      obtenerPagos()
    }
    const sincronizarSap = () => {
      if (!ordenesSeleccionadas.value.length) return Swal.fire('Error', 'Debes deleccionar al menos una orden para continuar', 'error')
      const array = []
      for (const i of ordenesSeleccionadas.value) {
        array.push(i.NumOv)
      }
      const dataSend = { ordenes: array, Consignacion: referenciaBancaria.value }
      _SyncPagosService.value.sincronizarPagosRecibidos(dataSend).then(() => {
        Swal.fire('Guardado', 'Sincronización enviada a SAP correctamente', 'success').then(() => {
          obtenerPagos()
          ordenesSeleccionadas.value = []
        })
      })
      cerrarModalSincronizarSap()
    }
    const cerrarModalSincronizarSap = () => {
      displayModalSincronizarSap.value = false
      referenciaBancaria.value = ''
    }

    const sumaSeleccion = () => {
      const suma = ordenesSeleccionadas.value.reduce((acumulador, orden) => acumulador + orden.Valor, 0)
      if (suma > 0) {
        return 'Valor Total: ' + suma
      } else {
        return ''
      }
    }

    const registarORCT = () => {
      _SyncPagosService.value.registrar().then(({ data }) => {
        console.log({ data })
        obtenerPagos()
        ordenesSeleccionadas.value = []
      })
    }

    const eliminarPago = (NumOv) => {
      _PagosRecibidosService.value.eliminarPago(NumOv).then(({ data }) => {
        Swal.fire('Eliminado', data.message, 'success')
        obtenerPagos()
        ordenesSeleccionadas.value = []
      })
    }

    onMounted(() => {
      obtenerPagos()
    })
    return {
      sumaSeleccion,
      offset,
      page,
      limit,
      filtros,
      listadoPagos,
      estados,
      onPage,
      obtenerPagos,
      ordenFiltrada,
      validacionOrdenes,
      guardarOrdenes,
      limpiarFiltros,
      ordenesSeleccionadas,
      displayModalSincronizarSap,
      sincronizarSap,
      referenciaBancaria,
      cerrarModalSincronizarSap,
      pageOptions,
      registarORCT,
      eliminarPago
    }
  }
}
</script>
<style lang="scss">
::-webkit-scrollbar {
  width: 0.4rem;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: rgb(189, 189, 189);
  border-radius: 9999px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}
::-webkit-input-placeholder { /* Edge */
  color: gray;
}
.idPadre{
  position: relative;
  cursor: pointer;
  .idHijoDelete{
    display: none;
  }
}
.idPadre:hover {
  .idHijoDelete{
    color: red;
    display: flex;
  }
}
</style>
